@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
/* ********* Global Color styling for the full app ********* */
:root {
  --main-bg-color: #f3f2ef;
  --Grey1: #333333;
  --Grey2: #4f4f4f;
  --Grey3: #828282;
  --Grey4: #bdbdbd;
  --Grey5: #e0e0e0;
  --Grey5--hover: #c5c5c5;
  --Grey6: #f2f2f2;
  --Blue: #4a9ef7;
  --Blue-hover: #1f86f4;
  --Container-grey: #f0f0f0;
  --Container-grey-hover: #d1d1cf;
  --Container-text: #707683;
  --Secondary-green: #14a6a6;
  --Secondary-green--hover: #0d7979;
  --Secondary-green-light: #15afaf;
  --color-grey-chatbox: #e9ecf4;
  --color-grey-line: #d7e0e9;
  --color-grey-30: #fafbfd;
  --color-grey-40: #fbfbfd;
  --color-grey-50: #f7fafc;
  --color-grey-100: #e3e8ee;
  --color-grey-dark-100: #7a8394;
  --color-grey-200: #c1c9d2;
  --color-grey-300: #a3acb9;
  --color-grey-400: #8792a2;
  --color-grey-500: #697386;
  --color-grey-600: #59617b;
  --color-grey-700: #3c4257;
  --color-grey-800: #2a2f45;
  --color-grey-900: #1a1f36;
}
/* ****************************************** */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
  overflow: hidden;
}

/** Custom scrollbar for all 'Overflows Y and X' 
[Always reuse the className="scrollbarY--custom"] on div element
if scrollbar is needed*/
.scrollbarY--custom::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6rem;
  border-radius: 0px 20px 0px 0px;
}

.scrollbarY--custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.scrollbarY--custom::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182);
  border-radius: 10px 20px 10px 10px;
}

/****** Custom Skeleton loader bar - Just like Material-UI's but without any dependencies *****/
.skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton-shimmer {
  width: 100%;
  height: 20px;
  border-radius: 4px;
  background: #f3f3f3;
  position: relative;
  overflow: hidden;
}

.skeleton-shimmer:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #ececec 0%,
    rgba(0, 0, 0, 0.05) 20%,
    #ececec 40%,
    #ececec 100%
  );
  background-repeat: no-repeat;
  background-size: 450px 400px;
  -webkit-animation: shimmer 1s linear infinite;
          animation: shimmer 1s linear infinite;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}

/*******************************************************/
/* ****** Importing Inter font package from Google fonts ******* */

body {
  font-family: 'Inter', sans-serif !important;
}

button:focus {
  outline: 0px;
}

:focus {
  outline: 0px;
}

.MuiFormLabel-asterisk {
  color: red;
}

.formControl {
  /* background: #f5faff; */
  min-width: 100% !important;
}

.formControl > .MuiOutlinedInput-root {
  border-radius: 6px !important;
}

.dropdown__formControl > label {
  margin: -5px 15px;
}

